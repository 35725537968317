.button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  padding: 8px;
  background-color: #fff;
  border-radius: 100px;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 4px);
}

html[data-no-touch] .button:hover {
  background-color: #fff;
  filter: brightness(0.98) drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 4px);
}

.icon {
  width: 34px;
  height: 34px;
  fill: #444;
}

@media screen and (max-width: 600px) {
  .button {
    display: none;
  }
}
