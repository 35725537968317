.content {
  flex-grow: 1;
}

.list {
  margin: 60px auto;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  height: 100%;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: #74809e;
  box-sizing: border-box;
}

.emptyButton {
  margin-top: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.item {
  display: block;
  text-decoration: none;
}

.item.error {
  display: flex;
}

html[data-no-touch] .item:hover {
  opacity: 0.8;
}

.item + .item {
  margin-top: 60px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #1a1a1a;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.info {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

avatar {
  flex-shrink: 0;
}

.author {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.time {
  flex-shrink: 0;
  margin-left: 0.3em;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}

.dot {
  margin-right: 0.3em;
}

.paidTag {
  flex-shrink: 0;
  margin-left: 8px;
  padding: 0 6px;
  font-size: 12px;
  line-height: 20px;
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  border: 1px solid #563bda;
  border-color: rgb(var(--theme-primary-color));
  border-radius: 50px;
  transform: translateY(0.5px);
}

.content {
  margin-top: 14px;
  font-size: 17px;
  line-height: 27px;
  color: #1a1a1a;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.more {
  display: inline-block;
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  font-weight: 600;
}
