.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tableRoot {
  position: relative;
}

.table {
  margin-top: 40px;
  padding-bottom: 100px;
}

.table thead th {
  padding-bottom: 10px;
}

.footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 30px;
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
  align-items: center;
}

.pagnaiton {
  margin-top: 0;
}

.updated {
  font-size: 14px;
  color: #74809e;
}

thead th.actionsColumn {
  width: 175px;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.icon {
  vertical-align: middle;
  position: relative;
  top: -2px;
  transform: translateX(-3px);
  width: 20px;
  height: 20px;
}
