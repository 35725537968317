.message {
  padding: 0 24px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.message.isSelf {
  justify-content: flex-end;
}

.message:first-child {
  margin-top: 30px;
}

.message:last-child {
  margin-bottom: 30px;
}

.message + .message {
  margin-top: 30px;
}

.avatar {
  order: 1;
  flex-shrink: 0;
  margin-right: 10px;
}

.message.isSelf .avatar {
  order: 3;
  margin-right: 0px;
  margin-left: 10px;
}

.content {
  order: 2;
  flex-shrink: 1;
  max-width: 500px;
  overflow: hidden;
}

.bubble {
  padding: 12px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #1a1a1a;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: #f4f4f4;
  border-radius: 10px;
}

.message.isSelf .bubble {
  background: rgba(86, 59, 218, 0.15);
}

.time {
  margin-top: 5px;
  margin-left: 2px;
  font-size: 15px;
  line-height: 1.4;
  color: #e0dfe6;
}

.message.isSelf .time {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .message {
    padding: 0 15px;
  }
}
