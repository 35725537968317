.field {
  display: flex;
  min-height: 84px;
  width: 100%;
  max-width: 660px;
}

.content {
  flex: 1;
  display: flex;
}

.name {
  flex-shrink: 0;
  width: 100px;
  font-size: 19px;
  line-height: 52px;
  color: #060e4b;
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  font-size: 19px;
  line-height: 52px;
  color: #060e4b;
}

.inputRoot {
  flex: 1;
}

.actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 52px;
  width: 170px;
}

.saveButton {
  padding: 0;
  width: 72px;
  height: 45px;
  box-sizing: border-box;
}

.cancelButton {
  margin-left: 10px;
  font-weight: 600;
  color: #5e6088;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

html[data-no-touch] .cancelButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.cancelButton:active,
html[data-no-touch] .cancelButton:active {
  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .content {
    flex-direction: column;
  }

  .actions {
    margin: 20px 0;
    padding-left: 0;
    height: auto;
  }
}

.domainInput {
  max-width: 150px;
}

.domainText {
  padding-left: 0.3em;
  flex-shrink: 0;
  align-self: center;
  font-size: 20px;
  color: #060e4b;
}

.tip {
  padding: 5px 0;
  font-size: 14px;
  line-height: 22px;
  color: #8688a5;
}

.field.disabled .tip {
  opacity: 0;
  transition: opacity 0.25s;
}

html[data-no-touch] .field.disabled:hover .tip {
  opacity: 1;
}

.publicationDescriptionField {
  min-height: 136px;
}

.avatarField {
  margin-bottom: 32px;
  max-width: 400px;
}

.avatarContent {
  align-items: center;
}

@media screen and (max-width: 600px) {
  .avatarField {
    margin-bottom: 0;
  }

  .avatarContent {
    align-items: flex-start;
  }
}

.messagesSetting {
  margin-top: 32px;
}
