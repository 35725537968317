.content {
  display: flex;
  align-items: center;
  height: 52px;
  font-size: 18px;
  line-height: 25px;
  color: #060e4b;
}

.email {
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button {
  flex-shrink: 0;
  font-size: 18px;
  line-height: 25px;
  color: #563bda;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
}

.button.bind {
  transform: translateX(-10px);
}

.button.edit {
  margin-left: 5px;
}
