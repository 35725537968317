.error {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.message {
  margin: 0;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.retry {
  margin-top: 30px;
}
