.view {
  padding: 0px 0px 50px;
  width: 400px;
}

.nav {
  margin-bottom: 25px;
  display: flex;
  padding: 15px 5px;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 5px;
}

.icon {
  width: 26px;
  height: 26px;
  fill: #060e4b;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.content {
  padding: 0 20px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 1.4;
  color: #060e4b;
  text-align: center;
}

.retry {
  margin: 10px auto 0;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #060e4b;
}
