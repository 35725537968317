.menuButton {
  position: fixed;
  top: 13px;
  left: 13px;
  z-index: 1;
}

.menuButtonIcon {
  height: 26px;
  width: 26px;
}

.sidebar {
  position: fixed;
  top: 82px;
  bottom: 82px;
  left: 0;
  padding: 30px 0 60px 60px;
  width: 260px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(113, 113, 246, 0.11);
  border-radius: 0 10px 10px 0;
  box-sizing: border-box;
  transform: translateX(-300px);
  transition: transform 0.2s ease-out;
  z-index: 1;
}

.sidebar.isVisible {
  transform: translateX(0);
}

.link {
  display: block;
  padding: 20px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #060e4b;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-out;
}

html[data-no-touch] .link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 600px) {
  .menuButton {
    position: fixed;
    top: 15px;
    left: 10px;
  }

  .menuButtonIcon {
    height: 22px;
    width: 22px;
  }
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #5e6088;
}

.brand a {
  margin: 0 0.3em 0 0.5em;
  display: flex;
  align-items: center;
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

html[data-no-touch] .brand a:hover {
  opacity: 0.8;
}

.button:active,
html[data-no-touch] .brand a:active {
  opacity: 0.6;
}

.brand img {
  margin-right: 3px;
  width: 16px;
  height: 16px;
}
