.toolbar {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  padding: 6px 0;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}

.toolbar::before,
.toolbar::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: -1px;
  width: 10px;
  background: #fff;
}

.toolbar::before {
  left: -10px;
}

.toolbar::after {
  right: -10px;
}

.button {
  padding: 2px;
  border-radius: 5px;
}

.button + .button {
  margin-left: 6px;
}

.button.isActive svg {
  fill: #563bda;
}

.dialog {
  position: relative;
  padding: 40px 40px 0;
  width: 300px;
}

@media screen and (max-width: 600px) {
  .dialog {
    padding: 30px;
  }
}

.title {
  margin: 0 0 40px;
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #060e4b;
}

.submit {
  display: block;
  margin-top: 44px;
  width: 100%;
}

.tip {
  display: block;
  margin: 20px 0;
  font-size: 13px;
  text-align: center;
  color: #a7a8ca;
  text-decoration: none;
}

.tip:hover {
  text-decoration: underline;
}
