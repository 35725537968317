.root {
  width: 100%;
  overflow: auto;
}

.table {
  width: 1360px;
  border-collapse: collapse;
  table-layout: fixed;
}

.table th,
.table td {
  padding: 0;
}

.table th:first-child {
  padding-left: 5px;
  padding-right: 16px;
  width: 26%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table th:last-child {
  padding-right: 5px;
  text-align: end;
}

.table td:last-child {
  text-align: end;
  padding-right: 5px;
}

.table thead {
  border-bottom: 1px solid #eaeaea;
}

.table thead th {
  padding-bottom: 18px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #5e6088;
  text-align: start;
}

.table tbody th,
.table tbody td {
  padding: 8px 0;
  font-weight: 600;
  font-size: 18px;
  color: #060e4b;
}

.table th a {
  color: inherit;
  text-decoration: none;
}

html[data-no-touch] .table th a:hover {
  text-decoration: underline;
}

.table tbody tr:first-child th,
.table tbody tr:first-child td {
  padding-top: 16px;
}

.table tbody tr.isError td {
  padding: 80px 0;
}

.table tbody tr.isEmpty td {
  padding: 80px 0;
  text-align: center;
  vertical-align: middle;
  font-size: 28px;
  font-weight: 400;
  color: #5e6088;
}

.table tbody tr {
  background: rgba(0, 0, 0, 0);
  transition: background 0.3s;
}

html[data-no-touch] .table tbody tr:hover {
  background: rgba(0, 0, 0, 0.02);
}

html[data-no-touch] .table tbody tr.isLoading:hover,
html[data-no-touch] .table tbody tr.isError:hover,
html[data-no-touch] .table tbody tr.isEmpty:hover {
  background: rgba(0, 0, 0, 0);
}
