.richtext {
  color: #1a1a1a;
  font-size: 17px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.richtext > :first-child {
  margin-top: 0;
}

.richtext p {
  margin: 1em 0;
}

.richtext a {
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  text-decoration: none;
}

html[data-no-touch] .richtext a:hover {
  text-decoration: underline;
}

.richtext h1 {
  margin: 1.3em 0 1em;
  font-size: 1.5em;
  line-height: 1.5;
  font-weight: 600;
}

.richtext h2 {
  margin: 1.3em 0 0.85em;
  font-size: 1.2em;
  line-height: 1.5;
  font-weight: 600;
}

.richtext h1 a,
.richtext h2 a {
  color: inherit;
  text-decoration: underline;
}

.richtext blockquote {
  margin: 1em 0 0;
  padding-left: 1em;
  border-left: 3px solid #563bda;
  border-left: 3px solid rgb(var(--theme-primary-color));
}

.richtext blockquote + blockquote {
  margin: 0;
  padding-top: 1em;
}

.richtext ul,
.richtext ol {
  display: table;
  margin: 1em 0;
  padding: 0;
  width: 100%; /* 保证可点击区域 */
  word-break: break-word; /* 避免 display:table 时 overflow-wrap bug */
}

.richtext p + ul,
.richtext p + ol {
  margin-top: -0.5em;
}

.richtext li ol,
.richtext li ul {
  margin: 0;
  display: table-row;
}

.richtext ol {
  counter-reset: ol;
}

.richtext li {
  display: table-row;
  list-style: none;
}

.richtext ol li::before,
.richtext ul li::before {
  display: table-cell;
  text-align: center;
}

.richtext ol li::before {
  width: 1.875em;
  counter-increment: ol;
  content: counter(ol) '.';
}

.richtext ul li::before {
  width: 1.25em;
  content: '•';
  font-size: 1.5em;
  line-height: 0.5; /* 避免因为字号变大而撑高 */
  transform: translateY(0.1em);
}

.richtext li p {
  margin: 0;
}

.richtext pre {
  margin: 1em 0;
  padding: 0.9em;
  font-size: 0.9em;
  font-family: Menlo, Monaco, Consolas, Andale Mono, lucida console, Courier New,
    monospace;
  word-break: normal;
  word-wrap: normal;
  white-space: pre;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #f6f6f6;
  border-radius: 4px;
}

.richtext figure {
  margin: 1em 0;
  display: flex;
  max-width: 100%;
}

.richtext figure > div {
  margin: auto;
  max-width: 100%;
}

.richtext img {
  margin: auto;
  display: block;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.richtext .imgLoading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: #aaa;
  opacity: 0;
  pointer-events: none;
}

.richtext .imgLoading.withAnimation {
  transition: opacity 0.2s;
}

.richtext .imgLoading.isVisible {
  opacity: 1;
}

.richtext .hrRoot {
  position: relative;
  margin: 1em auto;
  width: 50%;
  padding: 1em 25%;
}

.richtext hr {
  height: 1px;
  border: none;
  background: #eaeaea;
}

.richtext .paywallDividerRoot {
  position: relative;
  margin: 1em auto;
  width: 100%;
  padding: 2em 0;
}

.richtext .paywallDivider {
  height: 2px;
  border: none;
  background: #eaeaea;
}

.richtext .paywallDividerText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 36px;
  background: #f3f3f6;
  font-size: 16px;
  line-height: 36px;
  color: #060e4b;
  cursor: default;
  border-radius: 4px;
  user-select: none;
}

.richtext .paywallDividerText::before,
.richtext .paywallDividerText::after {
  content: '';
  position: absolute;
  top: 0;
  display: block;
  width: 10px;
  height: 100%;
  background: #fff;
}

.richtext .paywallDividerText::before {
  right: 100%;
}

.richtext .paywallDividerText::after {
  left: 100%;
}
