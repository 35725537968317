.header {
  position: relative;
  padding: 65px 0;
}

/* 直接给 .header 设置背景色时 Safari 有未知的渲染 Bug */
.header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f5ff;
  background-color: rgba(var(--theme-primary-color), 0.05);
}

.view {
  position: relative;
  margin: auto;
  padding: 0 40px;
  max-width: 660px;
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .view {
    padding: 0 20px;
  }
}

.header .menuButton {
  position: absolute;
}

@media screen and (max-width: 600px) {
  .header .menuButton {
    top: 5px;
    left: 5px;
  }
}

.shareButton {
  position: relative;
  margin-left: 15px;
  padding: 12px 0;
  width: 68px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  background: none;
  border: 1px solid #563bda;
  border-color: rgb(var(--theme-primary-color));
  border-radius: 14px;
}

.name {
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;
  color: #060e4b;
  text-align: center;
}

.name a {
  color: inherit;
  text-decoration: none;
}

.avatar {
  display: block;
  margin: 40px auto 0;
  width: 68px;
  height: 68px;
}

.description {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 25px;
  color: #060e4b;
}

.quote {
  margin-bottom: 8px;
  fill: #563bda;
  fill: rgba(var(--theme-primary-color));
}

.sign {
  margin-top: 8px;
  font-size: 17px;
  line-height: 24px;
  color: #5e6088;
  align-self: flex-end;
}

.author {
  font-weight: 600;
  text-decoration: none;
}

.actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.button {
  flex-shrink: 0;
}

@media screen and (max-width: 450px) {
  .description {
    font-size: 16px;
  }

  .sign {
    font-size: 15px;
  }

  .quote {
    margin-top: 10px;
    width: 40px;
  }

  .button {
    flex-shrink: 1;
    font-size: 16px;
  }

  .button + .button {
    margin-left: 10px;
  }
}

.tip {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.4;
  color: #6e6f82;
}

.tipIcon {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.tipButton {
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}
