.icon {
  margin-top: 130px;
  font-size: 54px;
  line-height: 1;
  text-align: center;
}

.title {
  margin: 30px auto;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.description {
  margin-top: 17px;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.url {
  padding: 26px;
  background: #dfdfe8;
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.button {
  display: block;
  margin: 30px auto 0;
  width: 100%;
}

.secondaryButton {
  margin: 10px auto 0;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #060e4b;
}

.divider {
  margin: 40px auto;
  position: relative;
  display: flex;
  justify-content: center;
  color: #74809e;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  width: 180px;
}

.divider::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  bottom: 0;
  margin: auto;
  width: 200%;
  height: 1px;
  transform: scale(0.5);
  background: #dfdfe8;
  z-index: -1;
}

.dividerText {
  padding: 0 22px;
  background: #fff;
}

.qrcode {
  display: block;
  margin: auto;
}

.tip {
  margin: 20px auto 100px;
  font-size: 14px;
  line-height: 1.6;
  color: #bbb;
  text-align: center;
}
