.dialog {
  position: relative;
  padding: 40px 60px 20px;
  width: 360px;
}

@media screen and (max-width: 600px) {
  .dialog {
    padding: 40px 30px 60px;
  }
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #060e4b;
}

.description {
  margin-top: 8px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #5e6088;
}

.input {
  margin-top: 40px;
}

.button {
  display: block;
  margin-top: 20px;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  height: auto;
  font-size: 18px;
}

.tip {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #5e6088;
  text-align: center;
}

.tip a {
  color: #563bda;
  text-decoration: none;
}

html[data-no-touch] .tip a:hover {
  text-decoration: underline;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

.confirmed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
