.page {
  margin: 0px auto 50px;
}

.title {
  margin: 35px 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.4;
  color: #060e4b;
}

.section + .section {
  margin-top: 20px;
}

.subtitle {
  margin: 0 0 22px;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.4;
  color: #060e4b;
}
