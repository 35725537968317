.root {
  position: relative;
  margin-right: 20px;
  display: inline-block;
}

.button {
  display: flex;
  align-items: center;
}

.buttonIcon {
  margin-left: 3px;
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  fill: #060e4b;
}

.disableMask {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  cursor: not-allowed;
}

.dropdown {
  width: 100%;
}

.link {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
  cursor: default;
}

.dialog {
  position: relative;
  padding: 40px;
  width: 300px;
}

@media screen and (max-width: 600px) {
  .dialog {
    padding: 30px;
  }
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.description {
  margin-top: 8px;
  font-size: 18px;
  line-height: 1.4;
  color: #5e6088;
  text-align: center;
}

.image {
  margin-top: 24px;
}

.input {
  margin-top: 40px;
}

.submit {
  display: block;
  margin-top: 44px;
  width: 100%;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
