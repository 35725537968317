.button {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  background: none;
  border: none;
  outline: none;
  border-radius: 14px;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}

html[data-no-touch] .button:hover {
  opacity: 0.8;
}

.button:active,
html[data-no-touch] .button:active {
  opacity: 0.6;
}

.button:disabled,
html[data-no-touch] .button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.defaultButton {
  color: #060e4b;
  background: #e0dfe5;
}

.primaryButton {
  color: #fff;
  background-color: #563bda;
  background-color: rgb(var(--theme-primary-color));
}

.secondaryButton {
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  background-color: #d9d3f7;
  background-color: rgba(var(--theme-primary-color), 0.2);
}

.textButton {
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

.linkButton {
  display: inline-block;
  text-decoration: none;
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

html[data-no-touch] .textButton:hover,
html[data-no-touch] .linkButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.textButton:active,
.linkButton:active,
html[data-no-touch] .textButton:active,
html[data-no-touch] .linkButton:active {
  opacity: 0.6;
}

.iconButton {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
  border-radius: 100px;
}

.iconButton svg {
  display: block;
}

html[data-no-touch] .iconButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.iconButton:active,
html[data-no-touch] .iconButton:active {
  opacity: 0.6;
}

.toggleButton {
  position: relative;
  padding: 0;
  width: 40px;
  height: 30px;
  border-radius: 15px;
  background-color: #a7a8ca;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggleButton:disabled {
  cursor: not-allowed;
}

.toggleButton::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  margin: auto;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 20px;
  transform: translateX(0px);
  transition: transform 0.2s;
}

.toggleButton.isOn {
  background-color: #563bda;
}

.toggleButton.isOn::after {
  transform: translateX(12px);
}
