.content {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.loading {
  flex-shrink: 0;
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #e0dfe6;
}

.error {
  padding: 50px 0;
}
