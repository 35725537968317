@import-normalize;

@font-face {
  font-family: 'Zhubai Sans';
  src: local('Helvetica');
  unicode-range: U+201c-201d, U+2018-2019;
}

body {
  margin: 0;
  font-family: Zhubai Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'PingFang SC', 'Microsoft YaHei', 'Source Han Sans SC',
    'Noto Sans CJK SC', 'WenQuanYi Micro Hei', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * 处理移动端导航栏的占位问题，兼容 Chrome，详情见：
 * https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
 */
html[data-iphone],
html[data-iphone] body,
html[data-iphone] #root,
html[data-ipad],
html[data-ipad] body,
html[data-ipad] #root {
  min-height: -webkit-fill-available;
}

html,
body,
#root {
  min-height: 100vh;
}

/* Remove tap highlight on iOS */
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
  --theme-primary-color: 86, 59, 218;
}
