.dialog {
  position: relative;
  padding: 40px 60px 20px;
  width: 360px;
}

@media screen and (max-width: 600px) {
  .dialog {
    padding: 40px 30px 60px;
  }
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #060e4b;
}

.description {
  margin-top: 8px;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #5e6088;
}

.input {
  margin-top: 40px;
}

.typeSetting {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 1.4;
  color: #060e4b;
}

.typeButtonRoot {
  position: relative;
}

.typeButton {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown {
  width: 100%;
}

.arrowIcon {
  width: 18px;
  height: 18px;
  transform: translateX(3px) rotate(-90deg);
  fill: #060e4b;
}

.expiresInput {
  margin-right: 0.5em;
  padding: 9px 5px;
  width: 3em;
  box-sizing: content-box;
  text-align: center;
}

.button {
  display: block;
  margin-top: 30px;
  width: 100%;
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
