.actionButtonRoot {
  position: relative;
  display: inline-block;
}

.actionButton {
  display: flex;
  align-items: center;
}

.actionButtonIcon {
  margin-left: 3px;
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  fill: #060e4b;
}

.dropdown {
  width: 130px;
}
