.wrapper {
  margin: auto;
  width: 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper:not(.uploading) {
  cursor: pointer;
}

html[data-no-touch] .wrapper:not(.uploading):hover {
  opacity: 0.7;
}

.avatarEditor {
  position: relative;
}

.avatar {
  display: block;
}

.invaildAvatar path {
  fill: #fe626c;
}

.invaildAvatar circle {
  fill: #ffd6d8;
}

.avatarEditorIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #f4f1fe;
  border: 2px solid #fff;
  border-radius: 50%;
}

.uploading .avatarEditorIcon {
  background: #fff;
}

.text {
  margin-top: 12px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #5e6088;
  white-space: nowrap; /* 避免错误文案折行 */
}

.text.isInvaild {
  color: #d97377;
}
