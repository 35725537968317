.placeholder {
  background: rgb(228, 230, 235);
  animation: placeholder 1s steps(10, end) 0s infinite alternate both;
}

.rectangle {
  border-radius: 1000px;
}

.circle {
  overflow: hidden;
  border-radius: 50%;
}

@keyframes placeholder {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}
