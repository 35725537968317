.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}

/**
  * 处理移动端导航栏的占位问题，详情见：
  * https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  */
html[data-iphone] .page,
html[data-ipad] .page {
  min-height: -webkit-fill-available;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.view {
  margin: auto;
  padding: 0 40px;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
}

.isSlim {
  max-width: 800px;
}

@media screen and (max-width: 600px) {
  .view {
    padding: 0 20px;
  }
}
