.root {
  position: relative;
  display: flex;
  justify-content: center;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.image {
  opacity: 0;
}

.image.isVisible {
  opacity: 1;
}
