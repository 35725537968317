.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  width: 350px;
  max-width: calc(100vw - 20px);
}

.title {
  margin: 20px 0;
  color: #060e4b;
  font-size: 32px;
}

.description {
  font-size: 16px;
  color: #5e6088;
}

.description a {
  color: #563bda;
  text-decoration: none;
}

.description a:hover {
  text-decoration: underline;
}
