.dialog {
  padding: 40px 50px 50px;
  width: 485px;
  box-sizing: border-box;
}

.dialog.fullwidth {
  padding-left: 0;
  padding-right: 0;
}

.title {
  margin: 0 0 34px;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  color: #060e4b;
  text-align: center;
}

.subtitle {
  margin: -28px 0 34px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #74809e;
}

.types {
  margin-bottom: 40px;
}

.type {
  display: flex;
  width: 100%;
  padding: 20px 12px;
  align-items: center;
  text-align: left;
  line-height: 1.4;
  color: #5e6088;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

html[data-no-touch] .type:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.type.isActive {
  color: #563bda;
  border-color: #563bda;
}

.type + .type {
  margin-top: 20px;
}

.typeIcon {
  margin-right: 14px;
  flex-shrink: 0;
}

.type.isActive .typeIcon {
  stroke: #563bda;
  fill: #563bda;
}

.typeName {
  font-weight: 700;
  font-size: 20px;
}

.typeDescription {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
}

.submitButton {
  margin: 40px auto 0;
  display: block;
  height: 56px;
  width: 100%;
  font-size: 18px;
}

.cancelButton {
  margin: 20px auto 0;
  display: block;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  color: #74809e;
}

.toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  line-height: 1.4;
  color: #060e4b;
}

.toggleButton {
  transform: scale(0.88);
}

.toggle.secondary {
  margin-top: 5px;
  font-size: 15px;
  line-height: 30px;
  color: #5e6088;
}

.image {
  display: block;
  width: 480px;
  height: 188px;
}
