.root {
  position: relative;
}

.dropdown {
  padding: 8px 0;
  width: 150px;
  box-sizing: border-box;
}

.menu {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 22px;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #5e6088;
}

.menu.wechat {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.qrcode {
  margin-top: 14px;
}

.menuIcon {
  margin-right: 4px;
  width: 24px;
  height: 24px;
  transform: translateY(1px);
}
