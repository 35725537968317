.avatar {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
  filter: brightness(0.98);
  border-radius: 50%;
  overflow: hidden;
}
