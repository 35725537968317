.page {
  align-items: center;
  background-image: linear-gradient(
      148deg,
      hsla(0, 0%, 100%, 0),
      rgba(197, 204, 255, 0.77)
    ),
    linear-gradient(49deg, transparent, rgba(230, 254, 240, 0.21)),
    linear-gradient(238deg, hsla(0, 0%, 100%, 0), #e6f7ff),
    linear-gradient(306deg, #fff, rgba(128, 218, 255, 0.8));
}

.title {
  margin: 250px 0 0 0;
  font-size: 40px;
  line-height: 1.1;
  color: #060e4b;
}

.description {
  margin-top: 30px;
  color: #060e4b;
  font-size: 20px;
  line-height: 1;
}

.contacts {
  margin: 70px 0 50px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

.image {
  display: block;
}

.text {
  margin-top: 30px;
  font-size: 20px;
  color: #060e4b;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text a {
  color: #563bda;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 100px;
  }

  .description {
    font-size: 16px;
  }

  .contacts {
    flex-direction: column;
  }

  .item + .item {
    margin-top: 30px;
  }
}
