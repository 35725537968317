.link {
  margin-right: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #5e6088;
}

@media screen and (max-width: 600px) {
  .writeLink {
    display: none;
  }
}
