.dropdown {
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  padding: 6px 0;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(113, 113, 246, 0.11);
  border-radius: 10px;
  opacity: 0;
  z-index: 1;
  white-space: nowrap;
}

.dropdown.topLeft {
  top: auto;
  bottom: calc(100% + 8px);
}

.dropdown.bottomRight {
  right: 0;
  left: auto;
}

.dropdown.visible {
  opacity: 1;
}

.menuButton {
  display: block;
  width: 100%;
  padding: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #060e4b;
  background: none;
  outline: none;
  border: none;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
}

.menuButton.danger {
  color: #d97377;
}

html[data-no-touch] .menuButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

html[data-no-touch] .menuButton.disabled:hover {
  background: none;
}
