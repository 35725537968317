.notification {
  position: fixed;
  top: 85px;
  left: 50%;
  margin: auto;
  padding: 0 20px;
  max-width: 300px;
  height: 42px;
  text-align: center;
  font-size: 16px;
  line-height: 42px;
  color: #060e4b;
  background: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 4px);
  border-radius: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 999;
  pointer-events: none;
}

.isWarning {
  color: #fff;
  background: #d97377;
}

.visible {
  pointer-events: initial;
}
