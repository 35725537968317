@media (prefers-color-scheme: dark) {
  .page {
    background-color: #2c2c2c;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #000;
  border-radius: 20px;
  color: #fff;
  height: 100vh;
}

.title {
  margin: 0;
  font-size: 32px;
}

.button {
  margin-top: 40px;
  color: #000;
  font-size: 22px;
  padding: 12px 24px;
  border-radius: 100px;
  background-color: #fff;
}

.tip {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
}

.tipIcon {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARwSURBVHgB7VdZbFRVGP7OnbuXtmoTLA+QYlwTHiC2UBfArWK1BGhiDRplidEHExcIGjEx1TRusYJEEXlQH3wy0RgeiNGYNqZOsaBxoUuIUhEhRZZuzLSz3d9zzsx0pu1t59475YXwJf/knHv/8893v//8/7kXuBxBHQ/cRIfrl2EOoKBI0E93P4FEby8ixzopXL0VRaIoQtT9cCWSp1pQbjGUaQbo4i46sfdqFIHiFBrsbYHJFsIIASY3C2U4+fE+FIHAhKjrwaVQk49KMixzUefh1LENdLhhOQIiuEKJf/bD4JooLC8aH5uKhthfexAQgQjRofvWQY3VQHNZrnLFtNQKCt+5EQHgmxBRm4r48d0wValIJE4YjDrSRsaddESLk8JgKx16rAw+4V+h8MsvwVKrsups3nkKFTW90ra8cjrto/J7enIBnJ7t8AlfhCi85nqwC9vkRs5AUxlXDdLEeAJys0efo57GBfABz4SIiIH9tx2Gco1UIANTz5GwjDxCIT7WUY7B/lb4gHeFwndU8yd+khOadNk2c/MSc0o4Xag0vJG6Vt2OuSSUVif6LgymTipzDsvMzW1r8j2pkqXzFjH4FlGzibkihPDyTQhFVrmVuZ1PyHAJJ5vl+Er8+NUjmAtC1P3hPGD0tWyZT4WVlybLmn4fTDRLvjaUbKbzewq2gcIKjX7yIi/hRVB5GcGZZrYxNWXTfeRaLV6Fvv2vFvq7WQlR3+OL4Qw9LWWHqG1nms2zc/5lNnP1kWtFDCW+mY6svTkwIZwNvwHdmY9Qik+SrlZWkkdIjt39oPAYeqICsf43AxGiztq7+GneCE2kigcjd7Ot3BrbpBn9ZAwZ68J66qpf7YuQLPPUAFcnoUPhT0czm22mcoQsZ1ZfMG6i+KO/7Sb61PRMCJ01W6CM3iZTRYlZzTKdiWWWWdgfqkhdfCk6drm+7k6rUzr5noXjLadRQldBYyiEM0MODrQn5Ljpfn5W2IXXIMFTF8VZ3LhzMavcEZmdUPuid2Cc3wHR8DzEDgSxlWL8JzZ/L1vd/8yMhOiPdQtx7ps+lMJGCJcWYutd5D2htHYJu/WH3uxldYIMNSto/+h9GHEb2f7mAWdGgc++5u78gbeuB671+kom/oOXDEaOtRJ9sZaxplT2cppQ25IGsO4DsHmFeX0H4H4PPQsc7EhPG+uAL9/2/jDSLyq2RtUanrpvMyE5mSNPlQP/vs77RJogeTSOo3/m4v/akxl4XS9VEntp4AOukj5BCMMHm4ChZTKB7keWu/G2suHeHKF7xMdPysd6YWKv6mM34LttL4gYjH9fVWKo7SjssYrcjvKOOCfw/S/pj426mgwhvxBrxswB2NetVDHy+/PQOJnM+ekX4sysr85MkggGkbrQeCXGhjepYJG6CWUCEJozCA7xaIPiOLF9oFDSV94vhfE3OCihz2VV0c+1tyDydyk00Z7FIZj9nkkfCTIVKnIpmTrO94HLPVdo4sAl6eSMO+Oqcc5acaIfV1AA/wNjRR+fWXPuVgAAAABJRU5ErkJggg==');
}
