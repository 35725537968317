.navContent {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.navs {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 400;
  color: #5e6088;
  font-size: 18px;
  text-decoration: none;
}

.link.unlogin {
  font-weight: 600;
  color: #060e4b;
}

.link + .link {
  margin-left: 40px;
}

html[data-no-touch] .link:hover {
  color: #060e4b;
}

.active {
  font-weight: 600;
  color: #060e4b;
}

.link.active::after {
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  height: 2px;
  background: #563bda;
  content: '';
}

.link.hasBadge::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -7px;
  margin: auto;
  transform: translateY(-10px);
  height: 12px;
  width: 12px;
  border: 2px solid #fff;
  border-radius: 100px;
  background: #d97377;
  content: '';
}

.hide {
  display: none;
}

.avatar {
  position: relative;
  margin-left: 40px;
  width: 36px;
  height: 36px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown {
  padding: 5px 0;
}

.dropdownMenu {
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  stroke: #060e4b;
  transform: translateY(0.5px);
}

@media screen and (min-width: 601px) {
  .dropdown {
    right: auto !important;
    left: 50% !important;
    padding: 5px 0 !important;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 600px) {
  .isLoggedIn .logo {
    display: none;
  }

  .isLoggedIn .navs {
    justify-content: flex-start;
  }

  .link + .link {
    margin-left: 15px;
  }

  .avatar {
    margin-left: 15px;
  }
}
