@media screen and (max-width: 850px) {
  .sideMenuButton {
    display: none;
  }
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.publication {
  font-weight: 700;
  font-size: 28px;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.post {
  margin: 0 auto 100px;
  flex: 1;
}

.title {
  margin: 60px 0 0 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #1a1a1a;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.tip + .title {
  margin-top: 36px;
}

@media screen and (max-width: 600px) {
  .navRoot {
    display: none;
  }

  .title {
    margin-top: 20px;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-top: 30px;
  line-height: 24px;
  text-decoration: none;
}

.avatar {
  flex-shrink: 0;
}

.author {
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  margin-top: 20px;
}

.footer {
  padding: 24px 0 150px;
  background-color: #f3f2f9;
  background-color: rgba(var(--theme-primary-color), 0.05);
}

.footerTip {
  color: #74809e;
  font-size: 16px;
  line-height: 22px;
}

.footerHead {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.footerName {
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.footerButton {
  margin-left: 10px;
  flex-shrink: 0;
}

.footerDescription {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
  color: #060e4b;
}

.footerSign {
  margin-top: 24px;
  font-size: 17px;
  line-height: 24px;
  color: #5e6088;
  text-align: end;
}

.footerAuthor {
  font-weight: 600;
}
