.page {
  padding: 80px 0;
}

.view {
  margin: 0 auto;
  width: 100%;
  max-width: 580px;
  padding: 0 15px 0;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .page {
    padding: 50px 0;
  }

  .view {
    padding: 0 15px 0;
  }
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.subtitle {
  margin: 16px 0 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  color: #74809e;
}

.section + .section {
  margin-top: 40px;
}

.sectionTitle {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #060e4b;
}

.sectionDescription {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
  color: #74809e;
}

.priceField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.priceField + .priceField {
  margin-top: 20px;
}

.priceFieldLeft {
  display: flex;
  align-items: center;
}

.priceField.isDisabled .priceFieldLeft {
  opacity: 0.3;
}

.priceFieldName {
  font-weight: 700;
  font-size: inherit;
  line-height: 1.4;
  color: #060e4b;
}

.priceFieldInput {
  margin: 0 0.3em 0 15px;
  padding: 5px 0;
  width: 5em;
  font: inherit;
  font-size: inherit;
  text-align: center;
  color: #060e4b;
  border: 2px solid #a7a8ca;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}

.priceFieldInput:focus {
  border-color: #563bda;
}

.priceFieldInput.isError {
  border-color: #d97377;
  color: #d97377;
}

.priceField.isDisabled .priceFieldInput.isError {
  border-color: #a7a8ca;
  color: #060e4b;
}

.priceFieldDiscount {
  font-size: inherit;
  line-height: 1.4;
  color: #74809e;
}

.priceFieldError {
  font-size: inherit;
  line-height: 1.4;
  color: #d97377;
}

.editorRoot {
  border: 1px solid #dfdfe8;
  border-radius: 14px;
  overflow: hidden;
}

.toolbar {
  padding-left: 10px;
  padding-right: 10px;
  border-top: none;
}

.editor {
  position: relative;
  margin: 0 16px 12px;
  min-height: 300px !important;
}

.preview {
  position: relative;
  margin: 60px auto 50px;
  padding: 90px;
  width: calc(100vw - 30px);
  max-width: 950px;
  display: flex;
  background: #fafafa;
  border: 1px dashed #8688a5;
  box-sizing: border-box;
  border-radius: 10px;
}

.previewText {
  position: absolute;
  top: 0;
  left: 0;
  height: 90px;
  width: 100%;
  font-size: 20px;
  color: #8688a5;
  line-height: 90px;
  text-align: center;
}

.previewView {
  flex-shrink: 1;
  background-color: #fff;
  box-shadow: 4px 4px 50px rgba(78, 78, 78, 0.2);
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .preview {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 750px) {
  .preview {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.submit {
  display: block;
  margin: 10px auto 50px;
  width: 180px;
}

@media screen and (max-width: 600px) {
  .priceFieldName {
    font-size: 14px;
  }

  .priceField {
    font-size: 14px;
  }

  .priceFieldInput {
    margin-left: 10px;
  }
}

.summary {
  white-space: pre-wrap;
  font-size: 16px;
}

.summary::placeholder {
  white-space: pre;
  line-height: 1.6;
}

.tip {
  margin-bottom: 50px;
  color: #74809e;
  line-height: 1.8;
  font-size: 16px;
}

.tipTitle {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}
