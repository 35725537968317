.bar {
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  height: 2px;
  overflow: hidden;
  pointer-events: none;
}

/* 保证 loading bar 不会超出容器 */
.bar::before {
  display: block;
  content: '';
  height: 2px;
  width: 100%;
  background: #8e99b3;
  transform: translateX(-100%);
  animation: LoadingBarForward 1s ease-in-out 0.2s infinite;
}

@keyframes LoadingBarForward {
  0% {
    transform: translateX(-100%);
  }

  40% {
    transform: translateX(0);
  }

  60% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
