.error {
  padding: 0 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.errorTitle {
  font-size: 24px;
}

.errorSubtitle {
  margin-top: 20px;
  font-size: 16px;
}

.subdomain {
  max-width: 15ex;
}

.domain {
  padding-left: 0.3em;
  flex-shrink: 0;
  align-self: center;
  font-size: 20px;
  color: #060e4b;
}
