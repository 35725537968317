.summary {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f3f6;
}

.section {
  flex: 1;
  width: 220px; /* 单个 item 默认宽度 */
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #f3f3f6;
  border-radius: 20px;
  overflow: hidden;
}

.section.consecutive {
  width: 50%;
  border-radius: 0px;
}

.section.consecutive:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.section.consecutive:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.section + .section {
  margin-left: 40px;
}

.section.consecutive + .section.consecutive {
  margin-left: 0px;
}

.item {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 220px;
}

.number {
  font-weight: 900;
  font-size: 50px;
  line-height: 1.2;
  color: #060e4b;
}

.prefixUnit {
  font-size: 0.6em;
  margin-right: 0.1em;
}

.diff {
  margin-top: 3px;
  font-size: 14px;
  line-height: 1.2;
  color: #74809e;
}

.diff.isPositive {
  color: #d97377;
}

.diff.isNegative {
  color: #29ad26;
}

.text {
  display: flex;
  align-items: center;
  margin-top: 14px;
  font-size: 16px;
  line-height: 1.2;
  color: #060e4b;
}

@media screen and (max-width: 800px) {
  .number {
    font-size: 44px;
  }

  .diff {
    font-size: 12px;
  }

  .text {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .summary {
    flex-direction: column;
  }

  .section,
  .section.consecutive {
    width: 100%;
  }

  .section.consecutive {
    border-radius: 20px;
  }

  .section + .section {
    margin-top: 30px;
    margin-left: 0;
  }
}
