@media screen and (max-width: 850px) {
  .sideMenuButton {
    display: none;
  }
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.publication {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.post {
  margin: 0 auto 100px;
  flex: 1;
}

.tip {
  margin-top: 20px;
  background: #f3f3f6;
  border-radius: 10px;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  color: #1a1a1a;
}

.title {
  margin: 60px 0 0 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #1a1a1a;
  word-wrap: break-word; /* fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.tip + .title {
  margin-top: 36px;
}

@media screen and (max-width: 600px) {
  .navRoot {
    display: none;
  }

  .title {
    margin-top: 20px;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-top: 30px;
  line-height: 24px;
  text-decoration: none;
}

.avatar {
  flex-shrink: 0;
}

.author {
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dot {
  margin: 0 0.5em;
}

.time {
  flex-shrink: 0;
  font-size: 16px;
  color: #5e6088;
}

.paidTag {
  flex-shrink: 0;
  margin-left: 10px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 22px;
  color: #563bda;
  color: rgb(var(--theme-primary-color));
  border: 1px solid #563bda;
  border-color: rgb(var(--theme-primary-color));
  border-radius: 50px;
}

.content {
  margin-top: 20px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.shareButton {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #29ad26;
  border: 1px solid #29ad26;
  border-radius: 100px;
}

.shareButtonIcon {
  margin-right: 3px;
  transform: translateY(-1px);
}

.footer {
  padding: 24px 0 150px;
  background-color: #f3f2f9;
  background-color: rgba(var(--theme-primary-color), 0.05);
}

.footerTip {
  color: #74809e;
  font-size: 16px;
  line-height: 22px;
}

.footerHead {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}

.footerName {
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #060e4b;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.footerButton {
  margin-left: 10px;
  flex-shrink: 0;
}

.footerDescription {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
  color: #060e4b;
}

.footerSign {
  margin-top: 24px;
  font-size: 17px;
  line-height: 24px;
  color: #5e6088;
  text-align: end;
}

.footerAuthor {
  font-weight: 600;
}

.previewSwitch {
  position: fixed;
  left: 50%;
  transform: translateX(-50%) scale(1.1);
  bottom: 50px;
  border-radius: 100px;
}
