.root {
  overflow: auto;
}

.view {
  padding: 40px 40px 60px;
  width: 800px;
}

.title {
  margin-bottom: 35px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.4;
  color: #060e4b;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
}

.table {
  margin-top: 35px;
  width: 100%;
}

.table th:first-child {
  width: 15%;
}

.table tbody td,
.table tbody th {
  padding: 12px 0;
}

.table th:last-child,
.table td:last-child {
  text-align: left;
}
