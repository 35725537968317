.wrapper {
  height: 72px;
}

.wrapper.transparent {
  height: 0;
}

.nav {
  position: fixed;
  top: 0;
  height: 72px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  z-index: 1;
}

.wrapper.transparent .nav {
  background: none;
  border-bottom: 1px solid transparent;
}
