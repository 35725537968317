.phoneInputRoot {
  position: relative;
}

.phoneInput {
  padding-left: 66px;
  box-sizing: border-box;
}

.areaCode {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  width: 56px;
  height: calc(100% - 20px);
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #060e4b;
  border-right: 1px solid #dfdfe8;
  cursor: default;
}

.verificationCodeButton {
  margin-left: 17px;
  flex-shrink: 0;
  width: 120px;
  padding-right: 0;
  padding-left: 0;
}
