.content {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
}

.content h1 {
  font-size: 50px;
  font-weight: 500;
}

.content div {
  font-size: 16px;
}

.content a {
  color: inherit;
}

.footer {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  font-size: 12px;
  color: #5e6088;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

html[data-no-touch] .footer a:hover {
  text-decoration: underline;
}
