.root {
  position: relative;
  margin: 1em 0;
}

.topMask {
  position: absolute;
  top: -4.2em;
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  height: 4.2em;
}

.paywall {
  padding: 40px 15px 30px;
  background: #fff;
  border: 1px solid #dfdfe8;
  border-radius: 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: #060e4b;
  text-align: center;
}

.subtitle {
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.4;
  color: #5e6088;
  text-align: center;
}

.name {
  display: inline;
  font-weight: 700;
}

.plans {
  display: flex;
  justify-content: center;
  margin: 35px 0;
}

.button {
  display: none;
}

.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  border: 1px solid #dfdfe8;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

html[data-no-touch] .plan:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.planPrice {
  font-weight: 700;
  line-height: 1.4;
  color: #060e4b;
}

.planName {
  margin-top: 20px;
  font-weight: 400;
  line-height: 1.4;
  color: #060e4b;
}

.discount {
  position: absolute;
  top: -11px;
  right: -10px;
  width: 54px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #c2131a;
  background-color: #ffc2c5;
  border-radius: 11px;
}

.plans.one .plan {
  display: none;
}

.plans.one .button {
  display: block;
}

.plans.four .plan,
.plans.three .plan,
.plans.two .plan {
  width: 140px;
}

.plans.four .plan + .plan {
  margin-left: 28px;
}

.plans.three .plan + .plan {
  margin-left: 35px;
}

.plans.two .plan + .plan {
  margin-left: 70px;
}

.plans.four .planPrice,
.plans.three .planPrice,
.plans.two .planPrice {
  font-size: 28px;
}

.plans.four .planName,
.plans.three .planName,
.plans.two .planName {
  font-size: 20px;
}

.plans.three .plan {
  width: 140px;
}

@media screen and (max-width: 800px) {
  .plans.four .plan {
    width: 130px;
  }

  .plans.four .plan + .plan {
    margin-left: 25px;
  }

  .plans.four .planPrice {
    font-size: 24px;
  }

  .plans.four .planName {
    font-size: 18px;
  }
}

@media screen and (max-width: 700px) {
  .plans.four .plan {
    width: 110px;
  }

  .plans.four .plan + .plan {
    margin-left: 20px;
  }

  .plans.four .planPrice {
    font-size: 20px;
  }

  .plans.four .planName {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .plans.four .plan {
    display: none;
  }

  .plans.four .button {
    display: block;
  }

  .plans.three .plan {
    width: 130px;
  }

  .plans.three .plan + .plan {
    margin-left: 25px;
  }

  .plans.three .planPrice {
    font-size: 24px;
  }

  .plans.three .planName {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .plans.three .plan {
    width: 110px;
  }

  .plans.three .plan + .plan {
    margin-left: 25px;
  }

  .plans.three .planPrice {
    font-size: 20px;
  }

  .plans.three .planName {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .plans.three .plan {
    display: none;
  }

  .plans.three .button {
    display: block;
  }

  .plans.two .plan {
    width: 130px;
  }

  .plans.two .plan + .plan {
    margin-left: 25px;
  }

  .plans.two .planPrice {
    font-size: 24px;
  }

  .plans.two .planName {
    font-size: 18px;
  }
}

.descriptionLinkRoot {
  display: flex;
  justify-content: center;
}

a.descriptionLink {
  display: flex;
  align-items: center;
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #74809e;
  text-decoration: none;
}

html[data-no-touch] a.descriptionLink:hover {
  text-decoration: none;
  opacity: 0.8;
}

.arrowIcon {
  width: 18px;
  height: 18px;
  transform: rotate(180deg);
}

.footer {
  display: flex;
  margin-top: 15px;
  height: 22pxpx;
  font-size: 16px;
  line-height: 22px;
  color: #74809e;
  justify-content: center;
}

.footerButton {
  padding: 0;
  font-weight: 400;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

.footerDot {
  padding: 0 0.5em;
}
