.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8688a5;
  font-size: 20px;
  height: 100%;
}

.nav {
  position: relative;
  flex-shrink: 0;
  display: flex;
  height: 55px;
  align-items: center;
  justify-content: center;
  color: #060e4b;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #dedfe8;
}

.nav.isWechat {
  display: none;
}

.navLink {
  text-decoration: none;
  color: inherit;
}

html[data-no-touch] .navLink:hover {
  opacity: 0.8;
}

.navLink:active,
html[data-no-touch] .navLink:active {
  opacity: 0.6;
}

.backButton {
  display: none;
}

.editor {
  position: relative;
  flex-shrink: 0;
  padding: 16px 12px 12px;
  font-size: 16px;
  line-height: 1.6em;
  border-top: 1px solid #dedfe8;
}

.textarea {
  display: block;
  padding: 0;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  border: none;
  outline: none;
  resize: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.textarea.isStatic {
  height: 9.6em;
}

@media screen and (max-height: 950px) {
  .textarea.isStatic {
    height: 8em;
  }
}

@media screen and (max-height: 850px) {
  .textarea.isStatic {
    height: 6.4em;
  }
}

.textarea:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: none;
}

.textarea.isAutoGrowing {
  display: none;
}

.editorFooter {
  display: flex;
  height: 3em;
  justify-content: flex-end;
  align-items: center;
}

.tip {
  font-size: 14px;
  color: #a7a8ca;
  margin-right: 10px;
}

.editorButton {
  padding: 8px 16px;
}

.textarea::placeholder {
  color: #a7a8ca;
}

@media screen and (max-width: 767px) {
  .backButton {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    padding-left: 5px;
  }

  .textarea.isStatic {
    display: none;
  }

  /* - [ ] 滚动条出现在最右侧 */
  .textarea.isAutoGrowing {
    display: block;
    max-height: 16em;
    padding-right: 52px;
  }

  .tip {
    display: none;
  }

  .editor {
    display: flex;
    padding: 16px 0 16px 12px;
  }

  .editorFooter {
    position: absolute;
    right: 12px;
    bottom: 16px;
    height: unset;
  }

  .editorButton {
    padding: 0;
    color: #563bda;
    background: none;
  }
}
