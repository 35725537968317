.page {
  height: 100vh;
  min-height: unset;
}

html[data-iphone] .page,
html[data-ipad] .page {
  height: -webkit-fill-available;
}

.topNav {
  flex-shrink: 0;
}

.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 500px;
  max-height: 1000px;
}

.table {
  flex: 1;
  display: flex;
  height: 100%;
  border: 1px solid #dedfe8;
  border-radius: 8px;
  overflow: hidden;
}

.left {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 360px;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  border-left: 1px solid #dedfe8;
}

.isSelected .right {
  background-color: #fff;
}

.nav {
  flex-shrink: 0;
  display: flex;
  height: 55px;
  align-items: center;
  justify-content: center;
  color: #060e4b;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #dedfe8;
}

.navLink {
  text-decoration: none;
  color: inherit;
}

html[data-no-touch] .navLink:hover {
  opacity: 0.8;
}

.navLink:active,
html[data-no-touch] .navLink:active {
  opacity: 0.6;
}

.list {
  flex: 1;
  overflow: auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8688a5;
  font-size: 20px;
  height: 100%;
}

.error.isInline {
  flex-grow: 0;
  height: auto;
  padding: 25px 0;
}

.conversation {
  display: flex;
  padding: 18px 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
  cursor: default;
}

.conversation:first-child {
  padding-top: 36px;
}

.conversation:last-child {
  padding-bottom: 36px;
}

html[data-no-touch] .conversation:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.conversation:active {
  background-color: rgba(0, 0, 0, 0.04);
  opacity: 0.8;
}

.conversation.isSelected {
  background-color: rgba(0, 0, 0, 0.04);
}

.avatar {
  position: relative;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
}

.avatar.withBadge::after {
  position: absolute;
  top: 0;
  right: 0px;
  height: 12px;
  width: 12px;
  border: 2px solid #fff;
  border-radius: 100px;
  background: #d97377;
  content: '';
}

.description {
  flex-grow: 1;
  margin-left: 14px;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
}

.info {
  display: flex;
  justify-content: space-between;
}

.user {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.username {
  font-weight: 600;
}

.time {
  flex-shrink: 0;
  font-size: 13px;
  color: #a7a8ca;
}

.lastMessage {
  margin-top: 8px;
  line-height: 20px;
  color: #74809e;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
  .left {
    width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .pageContent {
    padding: 0px;
    min-height: unset;
    max-height: unset;
  }

  .table {
    border: none;
    max-height: unset;
  }

  .left {
    flex: 1;
  }

  .right {
    display: none;
  }

  .nav {
    display: none;
  }

  .isSelected .topNav {
    display: none;
  }

  .isSelected .left {
    display: none;
  }

  .isSelected .right {
    display: flex;
    height: 100vh;
    width: 100vw;
  }

  html[data-iphone] .isSelected .right {
    height: -webkit-fill-available;
  }
}
