.pageContent {
  flex: 1;
}

.searchInput {
  margin-right: 20px;
  padding: 10px 14px;
  height: 45px;
  width: 200px;
}

@media screen and (max-width: 500px) {
  .searchInput {
    margin-right: 10px;
    width: 130px;
  }
}

.tableRoot {
  position: relative;
}

.table {
  margin-top: 40px;
  padding-bottom: 100px;
}

.table thead th {
  padding-bottom: 10px;
}

.table th:first-child {
  width: 500px;
}

.footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 30px;
  display: flex;
  padding: 0 5px;
  justify-content: space-between;
  align-items: center;
}

.actionButtonRoot {
  position: relative;
  display: inline-block;
}

.actionButton {
  display: flex;
  align-items: center;
}

.actionButtonIcon {
  margin-left: 3px;
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  fill: #060e4b;
}

.dropdown {
  width: 130px;
}

.pagination {
  margin-top: 30px;
  transform: translateX(-5px);
}

.paginationButton {
  padding: 10px;
  color: #060e4b;
}

html[data-no-touch] .paginationButton:hover {
  background: rgba(0, 0, 0, 0.05);
}

.paginationButton + .paginationButton {
  margin-left: 10px;
}

.modal {
  padding: 42px 60px 10px;
  width: 100vw;
  max-width: 480px;
  margin: auto;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 42px 20px;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.description {
  display: flex;
  justify-content: center;
  margin: 16px 0 43px;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  color: #5e6088;
  word-break: break-all;
}

.name {
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 46px;
  height: 46px;
}

.field + .field {
  margin-top: 10px;
}

.fieldName {
  color: #5e6088;
}

.fieldValue {
  font-weight: 700;
  color: #060e4b;
}

.input {
  margin-right: 0.5em;
  padding: 9px 5px;
  width: 3em;
  box-sizing: content-box;
  text-align: center;
}

.tip {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1.4;
  color: #74809e;
}
