.view {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 50px;
  box-sizing: border-box;
}

.title {
  margin: 0 0 40px;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.4;
  text-align: center;
  color: #060e4b;
}

.description {
  margin: -30px 0 40px;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  color: #74809e;
  font-weight: 400;
}

.approaches {
  display: flex;
}

.approach {
  position: relative;
  flex: 1;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a7a8ca;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #a7a8ca;
  border-radius: 20px;
  transition: background-color 0.2s;
}

.approach.wechat.hasSubscribed {
  color: #29ad26;
  border-color: #29ad26;
}

.approach.email.hasSubscribed {
  color: #563bda;
  border-color: #563bda;
}

.approach.wechat.hasSubscribed.loading,
.approach.email.hasSubscribed.loading {
  border-color: #a7a8ca;
}

html[data-no-touch] .approach:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.approach:disabled,
.approach.hasSubscribed:disabled,
html[data-no-touch] .approach:disabled {
  color: #a7a8ca;
  border: 1px solid #a7a8ca;
  opacity: 0.3;
}

html[data-no-touch] .approach:disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.approach + .approach {
  margin-left: 40px;
}

.approachIcon {
  width: 60px;
  height: 60px;
  fill: #a7a8ca;
}

.approachText {
  margin-top: 14px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
}

.approachTip {
  position: absolute;
  top: 100%;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 0 0;
  color: #555;
}

.approachCheckbox {
  position: absolute;
  top: 10px;
  right: 10px;
}

.loadingMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px; /* 和 .approach 一致 */
  opacity: 0;
  transition: opacity 0.25s;
}

.loadingMask.isVisible {
  opacity: 1;
}

.approach.wechat.hasSubscribed .approachIcon,
.approach.wechat.hasSubscribed .approachCheckbox {
  fill: #29ad26;
}

.approach.email.hasSubscribed .approachIcon,
.approach.email.hasSubscribed .approachCheckbox {
  fill: #563bda;
}

.approach.hasSubscribed:disabled .approachIcon {
  fill: #a7a8ca;
}

@media screen and (max-width: 600px) {
  .view {
    padding: 30px 20px 40px;
  }

  .title {
    font-size: 22px;
  }

  .description {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .approach {
    padding: 15px 0px;
  }

  .approach + .approach {
    margin-left: 20px;
  }

  .approachIcon {
    width: 50px;
    height: 50px;
  }

  .approachText {
    font-size: 16px;
  }
}
