.root {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.button {
  position: relative;
  width: 100px;
  height: 46px;
  padding: 0;
  overflow: hidden;
}

.button.hasSubscribed {
  color: #fff;
  background-color: rgba(var(--theme-primary-color), 0.4);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.hasSubscribed::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5e6088;
  opacity: 0.2;
  z-index: -1;
}

.icon {
  fill: #fff;
  transform: translateX(3px) rotate(-90deg);
  width: 18px;
  height: 18px;
}
