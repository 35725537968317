.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px auto 40px;
  height: 46px;
}

.left {
  font-weight: 700;
  font-size: 28px;
  color: #060e4b;
}

.right {
  display: flex;
}
