.page {
  height: 100vh;
}

.pageNav {
  flex-shrink: 0;
}

.pageContent {
  position: relative; /* editor scrollroot 依赖此定位 */
  flex-shrink: 1;
  overflow: auto;
}

.nav {
  display: flex;
}

.pageFooter {
  flex-shrink: 0;
  height: 72px;
}

.back {
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #74809e;
  padding: 0;
}

@media screen and (max-width: 500px) {
  .back {
    margin-left: 10px;
  }
}

.mobileMessage {
  padding: 0 20px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
}

.backIcon {
  transform: translateY(1px);
}

.view {
  margin-top: 0;
  /* 避免覆盖 Layout.View padding */
  padding-top: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.titleInput {
  padding: 0;
  display: block;
  width: 100%;
  border: none;
  resize: none;
  font-weight: 600;
  font-size: 28px;
  font-family: inherit;
  color: #1a1a1a;
  line-height: 40px;
  outline: none;
}

.titleInput::placeholder {
  color: #74809e;
  opacity: 1;
}

.toolbar {
  position: sticky;
  top: 0px;
  margin-top: 20px;
  z-index: 1;
}

.editor {
  flex: 1;
}

.actionsFooter {
  position: fixed;
  bottom: 0;
  height: 72px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}

.contactButton {
  transform: translateY(-72px);
}

.actionsFooterContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.buttons {
  position: relative;
}

.submitButton {
  width: 88px;
  padding-left: 0;
  padding-right: 0;
}

.nextButtonDisableMask {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 88px;
  cursor: not-allowed;
}

.draftStatus {
  font-size: 18px;
  color: #74809e;
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.draftStatus.isVisible {
  opacity: 1;
}

.retryButton,
.restoreButton {
  font-size: 18px;
  color: #563bda;
  padding: 0;
  font-weight: 400;
}
