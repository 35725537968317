.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0.6;
  z-index: 1;
}

.dialog {
  position: fixed;
  top: calc(50% - 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 4px 4px 50px rgba(78, 78, 78, 0.2);
  border-radius: 10px;
  z-index: 2;
  overflow: hidden;
  display: flex;
  max-width: calc(100vw - 20px);
}

@media screen and (max-width: 600px) {
  .dialog {
    top: 50px;
    transform: translate(-50%, 0);
  }
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 8px;
}

.view {
  position: fixed;
  top: calc(50% - 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex; /* TODO: 移除，会导致 overflow 有问题 */
  padding-bottom: env(safe-area-inset-bottom);
  max-width: calc(100vw - 20px);
  background: #fff;
  box-shadow: 4px 4px 50px rgba(78, 78, 78, 0.2);
  border-radius: 10px;
  overflow: auto;
  z-index: 2;
}

@media screen and (max-width: 600px) {
  .view {
    top: auto;
    left: 0;
    bottom: 0px;
    width: 100%;
    max-width: none;
    max-height: 80vh; /* 90vh 会导致 Safari 有低栏时看起来像是全屏 */
    transform: translate(0, 0);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .view .close {
    display: none;
  }
}
