.view {
  width: 750px;
}

.content {
  display: flex;
}

.summary,
.plans {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  box-sizing: border-box;
}

.summary {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #5e6088;
  white-space: pre-line;
  word-wrap: break-word; /* Fix: iOS Safari Bug */
  overflow-wrap: break-word;
  overflow: hidden;
}

.descriptionLink {
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  color: #060e4b;
  text-decoration: none;
}

.arrowIcon {
  transform: rotate(180deg);
  fill: #060e4b;
  width: 18px;
  height: 18px;
}

.plans {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  padding: 3px 0;
}

.plan {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: #060e4b;
  border: 2px solid #060e4b;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s;
}

html[data-no-touch] .plan:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.plan + .plan {
  margin-top: 30px;
}

.price span {
  margin-left: 2px;
}

.discount {
  position: absolute;
  top: -11px;
  right: -10px;
  width: 54px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #c2131a;
  background-color: #ffc2c5;
  border-radius: 11px;
}

.tip {
  margin-top: 16px;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  color: #a7a8ca;
}

.subscribed {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #74809e;
}

.subscribed + .tip {
  margin-top: 10px;
}

.subscribedName {
  flex-shrink: 1;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
}

.loginButton {
  flex-shrink: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #060e4b;
}

@media screen and (max-width: 600px) {
  .content {
    margin-top: -10px;
    flex-direction: column;
  }

  .summary {
    flex-basis: initial;
  }

  .plans {
    margin: 30px auto 50px;
    max-width: 400px;
    width: 100%;
    flex-basis: initial;
  }
}
