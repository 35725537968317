.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.table {
  padding-bottom: 100px;
}

.empty {
  padding: 30px 0;
  text-align: center;
}

.buttonRoot {
  position: relative;
  display: inline-block;
}

.button {
  display: flex;
  align-items: center;
}

.buttonIcon {
  margin-left: 3px;
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  fill: #060e4b;
}

.dropdown {
  width: 100%;
}
