.page {
  margin-top: 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}

.navTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  color: #060e4b;
}

.navLink {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  color: #5e6088;
}

.table {
  margin: 40px 0 100px;
}

.table th:last-child,
.table td:last-child {
  text-align: start;
}

.table tr {
  line-height: 46px;
}

.table td.nameColumn {
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  position: relative;
  margin: 80px 0 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4;
  color: #060e4b;
  text-align: center;
}

.subtitle {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.4;
  color: #5e6088;
  text-align: center;
}

.button {
  margin: 40px auto 0;
  display: block;
  height: 56px;
  width: 150px;
  font-size: 18px;
}

.link {
  margin-top: 20px;
  font-size: 16px;
  color: #060e4b;
  text-decoration: none;
}

html[data-no-touch] .link:hover {
  text-decoration: underline;
}

.previewRoot {
  display: flex;
  width: 100%;
}

.preview {
  flex-shrink: 1;
  margin: 80px auto;
  max-width: 800px;
  box-shadow: 4px 4px 50px rgba(78, 78, 78, 0.2);
  border-radius: 10px;
}
