.form {
  margin: 0 auto auto;
  padding: 0 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

.form::before {
  content: '';
  display: block;
  height: 20vh;
}

@media screen and (max-width: 600px) {
  .form::before {
    height: 30px;
  }

  .subtitle {
    margin-bottom: 20px;
  }
}

.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #060e4b;
}

.subtitle {
  margin: 6px 0 30px;
  font-size: 18px;
  line-height: 25px;
  color: #5e6088;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .subtitle {
    margin-bottom: 20px;
  }
}

.field {
  display: flex;
  flex-direction: column;
}

.field + .field {
  margin-top: 32px;
}

.field.withErrorMessage + .field {
  margin-top: 0px;
}

.inputWrapper {
  display: flex;
  width: 100%;
}

.input {
  padding: 12px 16px;
  width: 100%;
  font-size: 18px;
  line-height: 26px;
  color: #060e4b;
  background: #fff;
  border: 1px solid #dfdfe8;
  border-radius: 14px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
}

.input:focus {
  border-color: #563bda;
  border-color: rgb(var(--theme-primary-color));
}

.input::placeholder {
  color: #a7a8ca;
}

.input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  /* Safari 的颜色很诡异 */
  color: initial;
}

.textarea {
  resize: none;
  font-family: inherit;
}

.input.invalid,
.invalid .input {
  border: 1px solid #d97377;
  color: #d97377;
}

.input.invalid::placeholder,
.invalid .input::placeholder {
  color: #d97377;
}

.inputError {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #d97377;
}

.inputTip {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #5e6088;
  opacity: 0.7;
}

.submit {
  margin-top: 40px;
  width: 100%;
  height: 45px;
}

.field.withErrorMessage + .submit {
  margin-top: 8px;
}

.formError {
  padding: 10px 16px;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #d97377;
  text-align: center;
}

.formError.placeholder {
  opacity: 0;
}

.formError.placeholder.isVisible {
  opacity: 1;
}
