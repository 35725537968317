div.view {
  padding-bottom: 10px;
  width: 400px;
}

.form {
  margin: auto;
  width: 100%;
  max-width: 320px;
}

.button {
  display: block;
  margin-top: 25px;
  width: 100%;
}
